import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  OutlinedInput,
  styled,
} from '@material-ui/core'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import RoomIcon from '@mui/icons-material/Room'
import { debounce } from 'lodash'

import Spinner from '../loading/spinner'
import { useCart } from '../../hooks/useCart'
import { useLazyGetMobileAssociationsQuery } from '../../services/api/endpoints/associations'
import { QueryParams } from '../../services/api/endpoints/teams'

const StyledSearchResultText = styled('span')`
  color: #3c3835;
`

const StyledSearchResultItem = styled('div')`
  margin: 15px 0;
`

const StyledSearchResultHeader = styled('div')`
  margin-bottom: 25px;
`

const StyledSearchResultDetails = styled('div')`
  margin-bottom: 40px;
`

const ClearFix = styled('div')`
  clear: both;
`

const StyledImageContainer = styled('div')`
  float: right;
`

const StyledSearchResultHeaderContent = styled('div')`
  float: left;
  color: #3c3835;
`

const StyledFormControl = styled(FormControl)`
  background-color: #fff;
  border-color: white !important;
`

const StyledFormSearchContainer = styled(Box)`
  margin-top: 20px;
  margin-bottom: 40px;
`

const StyledSearchResult = styled(Box)`
  padding: 40px;
  padding-bottom: 10px;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 10px;
  font-size: 18px;
  box-shadow: 0px 5px 10px 0px #eee;
`

const StyledSearchResultHeaderTitle = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        fontSize: '15px',
      },
    }
  }}
  font-weight: bold;
  font-size: 20px;
`

const StyledImg = styled('img')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        height: '40px',
      },
    }
  }}
  height:50px;
`

const StyledContainer = styled(Container)`
  margin-top: 60px;
`

const searchResultIconStyles = {
  fill: '#008174',
  marginBottom: '-5px',
  marginRight: '10px',
}

const RefAssocList = () => {
  const cartType = 'referee'
  const [params, setParams] = useState<QueryParams>({
    offset: 0,
    limit: 100000,
    search: '',
    currentSeason: true,
  })

  const [trigger, result] = useLazyGetMobileAssociationsQuery()

  const { data: associations, isLoading, isFetching } = result

  const { setAssociation } = useCart({ type: cartType })

  useEffect(() => {
    trigger(params)
  }, [params])

  const handleClick = (associationId: string) => {
    setAssociation({ associationId, type: cartType })
    navigate(`/register/referee/profile`)
  }

  const handleSearch = debounce((value: string) => {
    setParams({ ...params, search: value })
  }, 500)

  return (
    <>
      {isLoading || isFetching ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        <StyledContainer>
          <StyledFormSearchContainer>
            <StyledFormControl fullWidth variant="outlined">
              <OutlinedInput
                id="outlined-search"
                type="text"
                placeholder="Enter the name of the association you wish to register to"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onChange={e => handleSearch(e.target.value)}
              />
            </StyledFormControl>
          </StyledFormSearchContainer>

          {associations && !!associations.length!! ? (
            associations.map((association: any) => (
              <StyledSearchResult key={association._id}>
                <Box onClick={() => handleClick(association._id)}>
                  <StyledSearchResultHeader>
                    <StyledSearchResultHeaderContent>
                      <StyledSearchResultHeaderTitle>
                        {association.name}
                      </StyledSearchResultHeaderTitle>
                    </StyledSearchResultHeaderContent>
                    {association.logo && (
                      <StyledImageContainer>
                        <StyledImg src={association.logo} alt="" />
                      </StyledImageContainer>
                    )}

                    <ClearFix />
                  </StyledSearchResultHeader>
                  <StyledSearchResultDetails>
                    <StyledSearchResultItem>
                      <RoomIcon style={searchResultIconStyles} />
                      <StyledSearchResultText>
                        {association.address.formatted}
                      </StyledSearchResultText>
                    </StyledSearchResultItem>
                  </StyledSearchResultDetails>
                </Box>
              </StyledSearchResult>
            ))
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <p>NO RESULTS FOUND</p>
              <p>
                Please check your search for any spelling errors or try a
                different search term
              </p>
            </Box>
          )}
        </StyledContainer>
      )}
    </>
  )
}

RefAssocList.defaultProps = {
  q: '',
}

export default RefAssocList
